/* eslint-disable max-lines -- it is what it is */
import fetchRetryFunction from 'fetch-retry'
import { GraphQLClient } from 'graphql-request'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { graphql } from '@/gql/generated'
import {
  ApplyTaskRefinementMutation,
  CreateMessageInput,
  CreateMessageMutation,
  CreateTaskInput,
  CreateTaskMutation,
  DeleteFileMutation,
  DeleteNoteMutation,
  DeleteNotificationsMutation,
  DeleteTaskMutation,
  DeleteThreadEventMutation,
  FetchActivityFlowDataQuery,
  FetchAllTasksQuery,
  FetchAllUsersQuery,
  FetchSpacesQuery,
  FetchTasksByIdQuery,
  FetchViewerQuery,
  FetchViewerWithNotificationsQuery,
  FetchViewerWithTasksQuery,
  GetThreadEventsQuery,
  MarkNotificationsAsReadMutation,
  OrderSubtasksMutation,
  RefineTaskInput,
  RefineTaskMutation,
  SetTaskStatusInput,
  SetTaskStatusMutation,
  SimilarTasksQuery,
  SimilarTasksQueryVariables,
  UpdateTaskInput,
  UpdateTaskMutation,
  FetchNotesQuery,
  UpdateNoteInput,
  UpdateNoteMutation,
  CreateNoteInput,
  CreateNoteMutation,
  FetchAgentsQuery,
  CreateAgentInput,
  CreateAgentMutation,
  UpdateAgentInput,
  UpdateAgentMutation,
  CreateForwardingEmailAddressMutation,
  OrderTasksInput,
} from '@/gql/generated/graphql'
import { getApiHost } from '@/lib/getApiHost'

export interface Api {
  applyTaskRefinement: (
    refinementId: string
  ) => Promise<ApplyTaskRefinementMutation>
  createMessage: (
    input: CreateMessageInput
  ) => Promise<CreateMessageMutation['createMessage']>
  createTask: (
    taskInput: CreateTaskInput
  ) => Promise<CreateTaskMutation['createTask']>
  deleteTask: (taskId: string) => Promise<DeleteTaskMutation>
  deleteThreadEvent: (
    threadEventId: string
  ) => Promise<DeleteThreadEventMutation>
  deleteNote: (id: string) => Promise<DeleteNoteMutation>
  deleteNotifications: (
    notificationIds: string[]
  ) => Promise<DeleteNotificationsMutation>
  fetchAllUsers: () => Promise<FetchAllUsersQuery>
  fetchAllWork: (spaceId?: string) => Promise<FetchAllTasksQuery['tasks']>
  fetchSpaces: () => Promise<FetchSpacesQuery>
  fetchSimilarTasks: (
    input: SimilarTasksQueryVariables
  ) => Promise<SimilarTasksQuery>
  fetchTaskThreadEvents: (
    taskIds: string
  ) => Promise<GetThreadEventsQuery['tasks']>
  fetchTasksById: (taskIds: string[]) => Promise<FetchTasksByIdQuery['tasks']>
  graphQLClient: GraphQLClient
  markNotificationsAsRead: (
    notificationIds: string[]
  ) => Promise<MarkNotificationsAsReadMutation>
  orderSubtasks: (
    orderedSubtaskIds: string[],
    insertAfterSubtaskWithId?: string
  ) => Promise<OrderSubtasksMutation>
  orderTasks: (input: OrderTasksInput) => Promise<void>
  refineTask: (input: RefineTaskInput) => Promise<RefineTaskMutation>
  setTaskStatus: (input: SetTaskStatusInput) => Promise<SetTaskStatusMutation>
  updateTask: (input: UpdateTaskInput) => Promise<UpdateTaskMutation>
  updateNote: (input: UpdateNoteInput) => Promise<UpdateNoteMutation>
  createNote: (input: CreateNoteInput) => Promise<CreateNoteMutation>
  fetchViewer: () => Promise<FetchViewerQuery>
  fetchViewerWithTasks: () => Promise<FetchViewerWithTasksQuery>
  fetchViewerWithNotifications: () => Promise<FetchViewerWithNotificationsQuery>
  fetchActivityFlowData: (taskId: string) => Promise<FetchActivityFlowDataQuery>
  fetchNotes: () => Promise<FetchNotesQuery>
  deleteFile: (fileId: string) => Promise<DeleteFileMutation>
  fetchAgents: () => Promise<FetchAgentsQuery>
  createAgent: (input: CreateAgentInput) => Promise<CreateAgentMutation>
  updateAgent: (input: UpdateAgentInput) => Promise<UpdateAgentMutation>
  createForwardingEmailAddress: () => Promise<CreateForwardingEmailAddressMutation>
}

const MAX_RETRY_ATTEMPT_ON_TOKEN_EXPIRED = 3

const ApiContext = createContext<Api | undefined>(undefined)
ApiContext.displayName = 'ApiContext'

interface GraphQLErrorResponse {
  errors: Array<{ extensions: { code: string } }>
}

const isGraphQLErrorResponse = (
  value: unknown
): value is GraphQLErrorResponse => {
  return (
    isObject(value) &&
    'errors' in value &&
    isArray(value.errors) &&
    value.errors.every(
      (error) =>
        isObject(error) &&
        'extensions' in error &&
        isObject(error.extensions) &&
        'code' in error.extensions &&
        isString(error.extensions.code)
    )
  )
}

export const useApi = () => {
  const context = useContext(ApiContext)
  if (!context) throw new Error('useApi must be used within a ApiProvider')
  return context
}

export const createTaskQueryKey = (taskId: string) => ['task', taskId]

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const graphQLClient = useMemo(() => {
    const fetchRetry = fetchRetryFunction(fetch)
    return new GraphQLClient(`${getApiHost()}/api/v1/graphql`, {
      fetch: async (input, init) =>
        await fetchRetry(input, {
          ...init,
          credentials: 'include',
          retryDelay: 300,
          retryOn: async (attempt, _error, response) => {
            // have to clone it because the graphql-request library also reads the response
            const body = (await response?.clone().json()) as unknown
            if (!isGraphQLErrorResponse(body)) {
              return false
            }

            const isRetryable = body.errors.some((error) =>
              [
                'INVALID_TOKEN_OR_TOKEN_EXPIRED',
                'AUTHORIZATION_COOKIE_MISSING',
              ].includes(error.extensions.code)
            )

            return Boolean(
              isRetryable && attempt < MAX_RETRY_ATTEMPT_ON_TOKEN_EXPIRED
            )
          },
        }),
    })
  }, [])

  const applyTaskRefinement: Api['applyTaskRefinement'] = useCallback(
    async (refinementId) => {
      return await graphQLClient.request(
        graphql(`
          mutation applyTaskRefinement($refinementId: ID!) {
            applyTaskRefinement(input: { id: $refinementId }) {
              id
            }
          }
        `),
        {
          refinementId,
        }
      )
    },
    [graphQLClient]
  )

  const createMessage: Api['createMessage'] = useCallback(
    async (input) => {
      const response = await graphQLClient.request(
        graphql(`
          mutation CreateMessage($input: CreateMessageInput!) {
            createMessage(input: $input) {
              id
              createdAt
              author {
                id
                name
              }
              body {
                text
              }
            }
          }
        `),
        {
          input,
        }
      )

      return response.createMessage
    },
    [graphQLClient]
  )

  const createTask: Api['createTask'] = useCallback(
    async (taskInput) => {
      const response = await graphQLClient.request(
        graphql(`
          mutation CreateTask($taskInput: CreateTaskInput!) {
            createTask(input: $taskInput) {
              id
              title
              createdAt
              status
              assignee {
                id
              }
              thread {
                id
              }
            }
          }
        `),
        {
          taskInput,
        }
      )

      return response.createTask
    },
    [graphQLClient]
  )

  const deleteTask: Api['deleteTask'] = useCallback(
    async (taskId: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteTask($taskId: ID!) {
            deleteTask(id: $taskId)
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const deleteThreadEvent: Api['deleteThreadEvent'] = useCallback(
    async (threadEventId: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteThreadEvent($threadEventId: ID!) {
            deleteThreadEvent(id: $threadEventId)
          }
        `),
        {
          threadEventId,
        }
      )
    },
    [graphQLClient]
  )

  const deleteNotifications: Api['deleteNotifications'] = useCallback(
    async (ids: string[]) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteNotifications($ids: [ID!]!) {
            deleteNotifications(ids: $ids)
          }
        `),
        {
          ids,
        }
      )
    },
    [graphQLClient]
  )

  const deleteNote: Api['deleteFile'] = useCallback(
    async (id: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteNote($id: ID!) {
            deleteNote(id: $id)
          }
        `),
        { id }
      )
    },
    [graphQLClient]
  )

  const fetchAllUsers: Api['fetchAllUsers'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchAllUsers {
          users {
            id
            name
            avatarUrl
          }
        }
      `),
      {}
    )
  }, [graphQLClient])

  const fetchAllWork: Api['fetchAllWork'] = useCallback(
    async (spaceId?: string) => {
      const response = await graphQLClient.request(
        graphql(`
          query FetchAllTasks($spaceId: ID) {
            tasks(spaceId: $spaceId) {
              id
              title
              createdAt
              completedAt
              status
              dueAt
              assignee {
                id
                name
                avatarUrl
              }
            }
          }
        `),
        { spaceId }
      )
      return response.tasks
    },
    [graphQLClient]
  )

  const fetchSimilarTasks: Api['fetchSimilarTasks'] = useCallback(
    async (input) => {
      return await graphQLClient.request(
        graphql(`
          query SimilarTasks(
            $taskId: ID!
            $minSimilarityScore: Float
            $limit: Int
          ) {
            similarTasks(
              taskId: $taskId
              minSimilarityScore: $minSimilarityScore
              limit: $limit
            ) {
              task {
                id
                title
                description
                completedAt
                status
                assignee {
                  id
                  name
                  avatarUrl
                }
              }
            }
          }
        `),
        {
          limit: input.limit,
          minSimilarityScore: input.minSimilarityScore,
          taskId: input.taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchSpaces = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchSpaces {
          spaces {
            id
            name
          }
        }
      `)
    )
  }, [graphQLClient])

  const fetchTaskThreadEvents: Api['fetchTaskThreadEvents'] = useCallback(
    async (taskId) => {
      const response = await graphQLClient.request(
        graphql(`
          query GetThreadEvents($taskId: ID!) {
            tasks(ids: [$taskId]) {
              thread {
                id
                events {
                  id
                  createdAt
                  payload {
                    __typename
                    ... on Message {
                      id
                      author {
                        avatarUrl
                        id
                        name
                      }
                      body {
                        text
                      }
                    }
                    ... on TaskUpdate {
                      id
                      actor {
                        avatarUrl
                        id
                        name
                      }
                      affectedTask {
                        id
                        description
                        title
                        status
                      }
                      type
                    }
                    ... on FileMetadata {
                      id
                      name
                      fileUrl
                      size
                      mimeType: type
                    }
                    ... on ImageFileMetadata {
                      id
                      name
                      fileUrl
                      size
                      width
                      height
                    }
                    ... on AgentInvocation {
                      id
                      createdAt
                      completedAt
                      agent {
                        id
                        name
                        avatarUrl
                      }
                      steps {
                        id
                        input
                        output
                        tool {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `),
        { taskId }
      )
      return response.tasks
    },
    [graphQLClient]
  )

  const fetchTasksById: Api['fetchTasksById'] = useCallback(
    async (taskIds) => {
      const response = await graphQLClient.request(
        graphql(`
          query FetchTasksById($taskIds: [ID!]!) {
            tasks(ids: $taskIds) {
              id
              title
              createdAt
              completedAt
              status
              dueAt
              description
              space {
                id
                name
              }
              assignee {
                id
                name
                avatarUrl
              }
              thread {
                id
              }
              subtasks {
                id
                title
                description
                createdAt
                completedAt
                status
                assignee {
                  id
                  name
                  avatarUrl
                }
              }
              parentTask {
                id
                title
                completedAt
                status
                parentTask {
                  id
                }
              }
            }
          }
        `),
        {
          taskIds,
        }
      )

      return response.tasks
    },
    [graphQLClient]
  )

  const markNotificationsAsRead: Api['markNotificationsAsRead'] = useCallback(
    async (notificationIds) => {
      return await graphQLClient.request(
        graphql(`
          mutation MarkNotificationsAsRead($notificationIds: [ID!]!) {
            markNotificationsAsRead(ids: $notificationIds) {
              id
              isUnread
            }
          }
        `),
        {
          notificationIds,
        }
      )
    },
    [graphQLClient]
  )

  const orderSubtasks: Api['orderSubtasks'] = useCallback(
    async (orderedSubtaskIds, insertAfterSubtaskWithId) => {
      return await graphQLClient.request(
        graphql(`
          mutation OrderSubtasks(
            $orderedSubtaskIds: [ID!]!
            $insertAfterSubtaskWithId: ID
          ) {
            orderSubtasks(
              input: {
                orderedSubtaskIds: $orderedSubtaskIds
                insertAfterSubtaskWithId: $insertAfterSubtaskWithId
              }
            ) {
              subtasks {
                id
              }
            }
          }
        `),
        {
          ...(insertAfterSubtaskWithId && { insertAfterSubtaskWithId }),
          orderedSubtaskIds,
        }
      )
    },
    [graphQLClient]
  )

  const orderTasks: Api['orderTasks'] = useCallback(
    async (input) => {
      await graphQLClient.request(
        graphql(`
          mutation OrderTasks($taskIds: [ID!]!, $insertAfterTaskWithId: ID) {
            orderTasks(
              input: {
                insertAfterTaskWithId: $insertAfterTaskWithId
                taskIds: $taskIds
              }
            )
          }
        `),
        input
      )
    },
    [graphQLClient]
  )

  const refineTask: Api['refineTask'] = useCallback(
    async ({ precedentTaskIds, previousTaskRefinementFeedback, taskId }) => {
      return await graphQLClient.request(
        graphql(`
          mutation refineTask(
            $taskId: ID!
            $precedentTaskIds: [ID!]
            $previousTaskRefinementFeedback: PreviousTaskRefinementFeedbackInput
          ) {
            refineTask(
              input: {
                taskId: $taskId
                precedentTaskIds: $precedentTaskIds
                previousTaskRefinementFeedback: $previousTaskRefinementFeedback
              }
            ) {
              id
              updates {
                change {
                  parentTaskId
                  title
                }
              }
              precedentTasks {
                assignee {
                  name
                  avatarUrl
                }
                id
                title
                completedAt
                createdAt
              }
            }
          }
        `),
        {
          precedentTaskIds,
          previousTaskRefinementFeedback,
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const setTaskStatus: Api['setTaskStatus'] = useCallback(
    async (input) => {
      return await graphQLClient.request(
        graphql(`
          mutation SetTaskStatus($id: ID!, $status: TaskStatus!) {
            setTaskStatus(input: { id: $id, status: $status }) {
              id
              status
            }
          }
        `),
        {
          id: input.id,
          status: input.status,
        }
      )
    },
    [graphQLClient]
  )

  const updateNote = useCallback(
    async (input: UpdateNoteInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateNote($id: ID!, $title: String, $body: String) {
            updateNote(input: { id: $id, title: $title, body: $body }) {
              id
              title
              body
            }
          }
        `),
        {
          body: input.body,
          id: input.id,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const createNote = useCallback(
    async (input: CreateNoteInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation CreateNote($title: String!, $body: String, $fileIds: [ID!]) {
            createNote(
              input: { title: $title, body: $body, fileIds: $fileIds }
            ) {
              id
              title
              body
            }
          }
        `),
        {
          body: input.body,
          fileIds: input.fileIds,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const updateTask: Api['updateTask'] = useCallback(
    async (input: UpdateTaskInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateTask(
            $id: ID!
            $title: String
            $description: String
            $assigneeId: ID
            $dueAt: DateTime
            $spaceId: ID
            $parentTaskId: ID
          ) {
            updateTask(
              input: {
                id: $id
                title: $title
                description: $description
                assigneeId: $assigneeId
                dueAt: $dueAt
                spaceId: $spaceId
                parentTaskId: $parentTaskId
              }
            ) {
              id
              title
              description
              assignee {
                id
                name
                avatarUrl
              }
              dueAt
              status
            }
          }
        `),
        {
          assigneeId: input.assigneeId,
          description: input.description,
          dueAt: input.dueAt,
          id: input.id,
          parentTaskId: input.parentTaskId,
          spaceId: input.spaceId,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const fetchViewer: Api['fetchViewer'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchViewer {
          viewer {
            id
            name
            avatarUrl
          }
        }
      `)
    )
  }, [graphQLClient])

  const fetchViewerWithTasks: Api['fetchViewerWithTasks'] =
    useCallback(async () => {
      return await graphQLClient.request(
        graphql(`
          query FetchViewerWithTasks {
            viewer {
              id
              name
              avatarUrl
              tasks {
                id
                title
                description
                completedAt
                status
                parentTask {
                  id
                  title
                }
                thread {
                  viewerNewMentionCount
                  viewerHasUpdates
                }
                assignee {
                  id
                }
              }
            }
          }
        `)
      )
    }, [graphQLClient])

  const fetchViewerWithNotifications: Api['fetchViewerWithNotifications'] =
    useCallback(async () => {
      return await graphQLClient.request(
        graphql(`
          query FetchViewerWithNotifications {
            viewer {
              id
              name
              avatarUrl
              notifications {
                id
                createdAt
                isUnread
                sourceMessage {
                  id
                  createdAt
                  author {
                    id
                    name
                    avatarUrl
                  }
                  body {
                    text
                  }
                }
                task {
                  id
                }
              }
            }
          }
        `)
      )
    }, [graphQLClient])

  const fetchActivityFlowData: Api['fetchActivityFlowData'] = useCallback(
    async (taskId) => {
      return await graphQLClient.request(
        graphql(`
          query FetchActivityFlowData($taskId: ID!) {
            getTaskActivityFlowData(taskId: $taskId) {
              activities {
                id
              }
              nodeId
              parentId
              adjacentNodes {
                nodeId
                traceCount
              }
              activityCount
              summary
            }
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchNotes: Api['fetchNotes'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchNotes {
          notes {
            id
            body
            title
            files {
              __typename
              ... on FileMetadata {
                id
                type
              }
              ... on ImageFileMetadata {
                id
                type
              }
            }
          }
        }
      `)
    )
  }, [graphQLClient])

  const deleteFile: Api['deleteFile'] = useCallback(
    async (id: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteFile($id: ID!) {
            deleteFile(id: $id)
          }
        `),
        { id }
      )
    },
    [graphQLClient]
  )

  const fetchAgents: Api['fetchAgents'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchAgents {
          agents {
            id
            name
            avatarUrl
            behavioralInstructions
          }
        }
      `)
    )
  }, [graphQLClient])

  const createAgent: Api['createAgent'] = useCallback(
    async (input: CreateAgentInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation CreateAgent(
            $name: String!
            $behavioralInstructions: String
            $avatarUrl: String
          ) {
            createAgent(
              input: {
                name: $name
                avatarUrl: $avatarUrl
                behavioralInstructions: $behavioralInstructions
              }
            ) {
              id
              name
              avatarUrl
              behavioralInstructions
            }
          }
        `),
        {
          avatarUrl: input.avatarUrl,
          behavioralInstructions: input.behavioralInstructions,
          name: input.name,
        }
      )
    },
    [graphQLClient]
  )

  const updateAgent: Api['updateAgent'] = useCallback(
    async (input: UpdateAgentInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateAgent(
            $id: ID!
            $name: String
            $behavioralInstructions: String
            $avatarUrl: String
          ) {
            updateAgent(
              input: {
                id: $id
                name: $name
                avatarUrl: $avatarUrl
                behavioralInstructions: $behavioralInstructions
              }
            ) {
              id
              name
              avatarUrl
              behavioralInstructions
            }
          }
        `),
        {
          avatarUrl: input.avatarUrl,
          behavioralInstructions: input.behavioralInstructions,
          id: input.id,
          name: input.name,
        }
      )
    },
    [graphQLClient]
  )

  const createForwardingEmailAddress: Api['createForwardingEmailAddress'] =
    useCallback(
      async () =>
        await graphQLClient.request(
          graphql(`
            mutation CreateForwardingEmailAddress {
              createForwardingEmailAddress {
                localPart
              }
            }
          `)
        ),
      [graphQLClient]
    )

  return (
    <ApiContext.Provider
      value={{
        applyTaskRefinement,
        createAgent,
        createForwardingEmailAddress,
        createMessage,
        createNote,
        createTask,
        deleteFile,
        deleteNote,
        deleteNotifications,
        deleteTask,
        deleteThreadEvent,
        fetchActivityFlowData,
        fetchAgents,
        fetchAllUsers,
        fetchAllWork,
        fetchNotes,
        fetchSimilarTasks,
        fetchSpaces,
        fetchTasksById,
        fetchTaskThreadEvents,
        fetchViewer,
        fetchViewerWithNotifications,
        fetchViewerWithTasks,
        graphQLClient,
        markNotificationsAsRead,
        orderSubtasks,
        orderTasks,
        refineTask,
        setTaskStatus,
        updateAgent,
        updateNote,
        updateTask,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
ApiProvider.displayName = 'ApiProvider'
