import { cva, VariantProps } from 'class-variance-authority'
import { ComponentProps, FC } from 'react'

import { Button } from '@/components/ui/button/Button'
import { cn } from '@/lib/utilities'
import { Root as SlotRoot } from '@radix-ui/react-slot'

const FileListItemRoot: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ...rest
}) => (
  <div
    className={cn(
      'flex h-12 w-sm items-center justify-between gap-2 rounded-lg border border-film-normal bg-mono-paper p-1',
      className
    )}
    {...rest}
  >
    {children}
  </div>
)
FileListItemRoot.displayName = 'FileListItemRoot'

const FileListItemContent: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ...rest
}) => (
  <div className={cn('flex min-w-0 items-center gap-2', className)} {...rest}>
    {children}
  </div>
)
FileListItemContent.displayName = 'FileListItemContent'

const FileListItemIcon: FC<ComponentProps<'div'> & { asChild: boolean }> = ({
  asChild,
  children,
  className,
  ...rest
}) => {
  const Comp = asChild ? SlotRoot : 'div'
  return (
    <Comp
      className={cn('size-10 flex-shrink-0 rounded-md p-1.5', className)}
      {...rest}
    >
      {children}
    </Comp>
  )
}
FileListItemIcon.displayName = 'FileListItemIcon'

const FileListItemLabelGroup: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ...rest
}) => (
  <div
    className={cn('flex min-w-0 flex-col justify-center', className)}
    {...rest}
  >
    {children}
  </div>
)
FileListItemLabelGroup.displayName = 'FileListItemLabelGroup'

const labelVariants = cva('truncate', {
  defaultVariants: {
    size: 'sm',
  },
  variants: {
    size: {
      lg: 'text-sm font-medium text-mono-ink-strong',
      sm: 'text-xs text-mono-ink-subtle',
    },
  },
})
const FileListItemLabel: FC<
  ComponentProps<'label'> & VariantProps<typeof labelVariants>
> = ({ children, className, size, ...rest }) => (
  <label className={cn(labelVariants({ className, size }))} {...rest}>
    {children}
  </label>
)
FileListItemLabel.displayName = 'FileListItemLabel'

const FileListItemButtonGroup: FC<ComponentProps<'div'>> = ({
  children,
  className,
  ...rest
}) => (
  <div className={cn('flex gap-1', className)} {...rest}>
    {children}
  </div>
)
FileListItemButtonGroup.displayName = 'FileListItemButtonGroup'

const FileListItemButton: FC<ComponentProps<typeof Button>> = ({
  children,
  className,
  ...rest
}) => (
  <Button
    className={cn('size-8 min-h-8 min-w-8 rounded-full p-1', className)}
    {...rest}
  >
    {children}
  </Button>
)
FileListItemButton.displayName = 'FileListItemButton'

export const FileListItem = {
  Button: FileListItemButton,
  ButtonGroup: FileListItemButtonGroup,
  Content: FileListItemContent,
  Icon: FileListItemIcon,
  Label: FileListItemLabel,
  LabelGroup: FileListItemLabelGroup,
  Root: FileListItemRoot,
}

export default FileListItem
