import { v7 as uuidv7 } from 'uuid'

export interface FileListItem {
  id: string
  file: File
}

export const createFileListItem = (file: File): FileListItem => ({
  file,
  id: process.env.NODE_ENV === 'test' ? file.name : uuidv7(),
})
