import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  KeyboardEvent,
} from 'react'

import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { Button } from '@/components/ui/button/Button'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { FileTypeIcon } from '@/features/task/components/chat/FileTypeIcon'
import { getFileType } from '@/features/task/components/chat/supportedFileTypes'
import { useDeleteFile } from '@/features/task/hooks/useDeleteFile'
import { FileListItem } from '@/lib/createFileListItem'
import { UploadState } from '@/lib/hooks/useFileUpload'
import { isImageFile } from '@/lib/utilities'

const GenericFile = ({ file }: { file: File }) => {
  return (
    <div className="flex h-26 w-3xs flex-col justify-between rounded-lg border border-film-strong bg-film-subtle p-2">
      <FileTypeIcon
        fileType={file.type}
        defaultIconBackgroundColor="white"
        className="size-6 rounded-sm p-0.5"
      />
      <label className="truncate text-sm text-mono-ink-strong">
        {file.name}
      </label>
      <label className="text-xs text-mono-ink-subtle">
        {getFileType(file.type)}
      </label>
    </div>
  )
}

GenericFile.displayName = 'GenericFile'

const ChatMessageInputFilePreview = ({
  fileListItem,
  filesState,
  onDelete,
}: {
  fileListItem: FileListItem
  filesState: Record<string, UploadState>
  onDelete: (fileName: string) => void
}) => {
  const { file, id: fileListItemId } = fileListItem

  const handleDelete = useCallback(() => {
    onDelete(fileListItemId)
  }, [onDelete, fileListItemId])

  const uploadState = useMemo(
    () => filesState[fileListItemId],
    [filesState, fileListItemId]
  )
  const showProgressBar = useMemo(() => !uploadState?.done, [uploadState])
  const progressBarInlineStyles = useMemo(
    () => ({
      width: `${uploadState?.progress ?? 0}%`,
    }),
    [uploadState]
  )

  return (
    <div key={fileListItemId} className="relative" data-testid="file-preview">
      {isImageFile(file) ? (
        <img
          src={URL.createObjectURL(file)}
          alt={file.name}
          className="h-24 w-24 rounded-md object-cover"
        />
      ) : (
        <GenericFile file={file} />
      )}
      <Tooltip content="Delete">
        <Button
          variant="solid"
          className="focus:border-unset absolute -top-1 -right-1 size-6 min-h-0 rounded-sm border-2 border-mono-paper p-0"
          onClick={handleDelete}
        >
          <CrossLargeIcon className="size-5 p-0 text-white" />
        </Button>
      </Tooltip>
      {showProgressBar && (
        <div className="absolute bottom-0 left-0 h-2 w-full overflow-hidden bg-gray-100">
          <div
            role="progressbar"
            className="absolute h-full bg-blue-500 transition-all duration-300"
            style={progressBarInlineStyles}
          />
        </div>
      )}
    </div>
  )
}

ChatMessageInputFilePreview.displayName = 'ChatMessageInputFilePreview'

export const ChatMessageInputFileList = ({
  fileList,
  filesState,
  onDelete,
  onKeyPress,
  setFilesState,
}: {
  fileList: FileListItem[]
  filesState: Record<string, UploadState>
  onKeyPress?: (event: KeyboardEvent<HTMLDivElement>) => void
  onDelete: (fileId: string) => void
  setFilesState: Dispatch<SetStateAction<Record<string, UploadState>>>
}) => {
  const { mutate: deleteFile } = useDeleteFile()
  const handleDelete = useCallback(
    (fileId: string) => {
      const fileState = filesState[fileId]
      const fileIdServer = fileState?.data?.id

      if (fileState && !fileState.done) {
        fileState.abort?.()
      }

      onDelete(fileId)
      setFilesState((previousState) => {
        const { [fileId]: _, ...newState } = previousState
        return newState
      })

      if (fileIdServer) {
        deleteFile(fileIdServer)
      }
    },
    [filesState, onDelete, setFilesState, deleteFile]
  )

  const reference = useCallback((node: HTMLDivElement) => {
    if (node) {
      node.focus()
    }
  }, [])

  return (
    <div
      className="mb-3 flex flex-row flex-wrap items-center gap-2 border-b border-film-strong px-3 pb-3 focus:outline-hidden"
      onKeyDown={onKeyPress}
      ref={reference}
      tabIndex={-1}
      data-testid="file-list"
    >
      {fileList.map((fileListItem) => (
        <ChatMessageInputFilePreview
          fileListItem={fileListItem}
          filesState={filesState}
          onDelete={handleDelete}
          key={fileListItem.id}
        />
      ))}
    </div>
  )
}

ChatMessageInputFileList.displayName = 'ChatMessageInputFileList'
