import noop from 'lodash/noop'
import { useCallback, useState } from 'react'

import { FileMetadata } from '@/gql/generated/graphql'
import { FileListItem } from '@/lib/createFileListItem'
import { useXhrUpload } from '@/lib/hooks/useXhrUpload'

export interface UploadState {
  loading: boolean
  data?: FileMetadata
  error?: Error
  progress: number
  abort?: () => void
  done: boolean
}

interface Properties {
  onFileUploaded?: (data: FileMetadata) => void
}
export const useFileUpload = ({ onFileUploaded = noop }: Properties = {}) => {
  const [filesState, setFilesState] = useState<Record<string, UploadState>>({})
  const xhrUpload = useXhrUpload()

  const onFileUploadProgress = useCallback(
    (progress: number, fileListItem: FileListItem) => {
      setFilesState((previous) => ({
        ...previous,
        [fileListItem.id]: {
          ...previous[fileListItem.id],
          done: false,
          loading: progress !== 100,
          progress,
        },
      }))
    },
    []
  )

  const upload = useCallback(
    (fileListItems: FileListItem[]) => {
      for (const fileListItem of fileListItems) {
        const abortController = xhrUpload({
          fileListItem,
          onError: (error) => {
            setFilesState((previous) => ({
              ...previous,
              [fileListItem.id]: {
                done: true,
                error,
                loading: false,
                progress: 0,
              },
            }))
          },
          onProgress: onFileUploadProgress,
          onSuccess: (fileMetadata) => {
            setFilesState((previous) => ({
              ...previous,
              [fileListItem.id]: {
                data: fileMetadata,
                done: true,
                loading: false,
                progress: 100,
              },
            }))
            onFileUploaded(fileMetadata)
          },
        })

        setFilesState((previous) => ({
          ...previous,
          [fileListItem.id]: {
            ...previous[fileListItem.id],
            abort: abortController,
            done: false,
            loading: true,
            progress: 0,
          },
        }))
      }
    },
    [onFileUploadProgress, onFileUploaded, xhrUpload]
  )

  return { filesState, setFilesState, upload }
}
