/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Date with time (isoformat) */
  DateTime: { input: string; output: string }
  Upload: { input: any; output: any }
  /** Represents NULL values */
  Void: { input: any; output: any }
}

/** Represents a distinct activity in the system. */
export type Activity = {
  __typename?: 'Activity'
  /** Unique identifier for the activity. */
  id: Scalars['ID']['output']
  /** A summary or brief description of this node's purpose. */
  summary: Scalars['String']['output']
}

/** Represents a cluster of activities and its associated flow in the system. */
export type ActivityFlowDataNode = {
  __typename?: 'ActivityFlowDataNode'
  /** A list of activities associated with this node. */
  activities: Array<Activity>
  /** The total number of activities in this node. */
  activityCount: Scalars['Int']['output']
  /** A list of nodes adjacent to this one in the flow. */
  adjacentNodes: Array<AdjacentActivityFlowDataNode>
  /** The id of the node. */
  nodeId: Scalars['Int']['output']
  /** The id of the parent node. */
  parentId?: Maybe<Scalars['Int']['output']>
  /** A summary or brief description of this node's purpose. */
  summary: Scalars['String']['output']
}

/** Represents a node that is adjacent to another in the activity flow. */
export type AdjacentActivityFlowDataNode = {
  __typename?: 'AdjacentActivityFlowDataNode'
  /** Unique identifier for the adjacent node. */
  nodeId: Scalars['Int']['output']
  /** The total number of traces going from the current to the adjacent node. */
  traceCount: Scalars['Int']['output']
}

/** An Agent is an automated User that interacts with the platform according to its defined behaviour. */
export type Agent = {
  __typename?: 'Agent'
  /** The url of the agent's avatar. */
  avatarUrl?: Maybe<Scalars['String']['output']>
  /** The behavioral instructions for the agent. */
  behavioralInstructions?: Maybe<Scalars['String']['output']>
  /** The id of the agent. */
  id: Scalars['ID']['output']
  /** The name of the agent. */
  name: Scalars['String']['output']
}

/** The invocation of an agent. */
export type AgentInvocation = {
  __typename?: 'AgentInvocation'
  /** The agent that was invoked. */
  agent: Agent
  /** The date and time the agent invocation completed. The invocation is still in progress if this field is null. */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  /** The date and time the agent was invoked. */
  createdAt: Scalars['DateTime']['output']
  /** The id of the agent invocation. */
  id: Scalars['ID']['output']
  /** The steps of the agent invocation. */
  steps: Array<ToolCallStep>
}

export type ApplyTaskRefinementInput = {
  /** The id of the TaskRefinement to apply. */
  id: Scalars['ID']['input']
}

/**
 *
 * A change request, for now, only applicable to Tasks.
 *
 */
export type ChangeRequest = {
  __typename?: 'ChangeRequest'
  /** The change to be made to the Task. */
  change: TaskChange
}

export type CreateAgentInput = {
  /** The URL of the agent's avatar. */
  avatarUrl?: InputMaybe<Scalars['String']['input']>
  /** The instructions for the agent that will define their general behavior. If no instructions are provided, the Agent will behave according to our platform baseline. */
  behavioralInstructions?: InputMaybe<Scalars['String']['input']>
  /** The name of the agent. */
  name: Scalars['String']['input']
}

export type CreateMessageInput = {
  /** The message body. */
  body: MessageBodyInput
  /** The id of the thread the message is attached to. */
  threadId: Scalars['ID']['input']
}

export type CreateNoteInput = {
  /** An optional body of the note. */
  body?: InputMaybe<Scalars['String']['input']>
  /** An optional list of file ids to attach to the note. */
  fileIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** The title of the note. Can not be empty string. */
  title: Scalars['String']['input']
}

export type CreateSpaceInput = {
  /** The name of the space. Can not be empty string. */
  name: Scalars['String']['input']
}

export type CreateTaskInput = {
  /** The id of an optional user to assign the task to. */
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  /** An optional description of a task. */
  description?: InputMaybe<Scalars['String']['input']>
  /** The id of an optional parent task. */
  parentTaskId?: InputMaybe<Scalars['ID']['input']>
  /** The id of a space to which the task should belong. If omitted, the task is created in the default space. */
  spaceId?: InputMaybe<Scalars['ID']['input']>
  /** The title of the task. Can not be empty string. */
  title: Scalars['String']['input']
}

/** Contains the metadata of a file. */
export type FileMetadata = {
  __typename?: 'FileMetadata'
  /** The date and time the file was created. */
  createdAt: Scalars['DateTime']['output']
  /** The URL to download the file. */
  fileUrl: Scalars['String']['output']
  /** The id of the file. */
  id: Scalars['ID']['output']
  /** The name of the file. */
  name: Scalars['String']['output']
  /** The size of the file in bytes. */
  size: Scalars['Int']['output']
  /** The MIME type of the file. */
  type: Scalars['String']['output']
}

export type FileMetadataImageFileMetadata = FileMetadata | ImageFileMetadata

/** Contains an email address. */
export type ForwardingEmailAddress = {
  __typename?: 'ForwardingEmailAddress'
  /** The local part of the email address. */
  localPart: Scalars['String']['output']
}

/** Contains the metadata of an image file. */
export type ImageFileMetadata = {
  __typename?: 'ImageFileMetadata'
  /** The date and time the file was created. */
  createdAt: Scalars['DateTime']['output']
  /** The URL to download the file. */
  fileUrl: Scalars['String']['output']
  /** The height of the image. */
  height: Scalars['Int']['output']
  /** The id of the file. */
  id: Scalars['ID']['output']
  /** The name of the file. */
  name: Scalars['String']['output']
  /** The size of the file in bytes. */
  size: Scalars['Int']['output']
  /** The MIME type of the file. */
  type: Scalars['String']['output']
  /** The width of the image. */
  width: Scalars['Int']['output']
}

/** A chat message send by a user. */
export type Message = {
  __typename?: 'Message'
  /** The author who sent the message. */
  author: User
  /** The body of a message containing the message text. */
  body: MessageBody
  /** The date and time the message was created. */
  createdAt: Scalars['DateTime']['output']
  /** The automatically generated id of a message. */
  id: Scalars['ID']['output']
}

/** The body of a message. */
export type MessageBody = {
  __typename?: 'MessageBody'
  /** The text of a message body. */
  text: Scalars['String']['output']
}

/** Input type for message body. */
export type MessageBodyInput = {
  fileIds?: InputMaybe<Array<Scalars['ID']['input']>>
  text: Scalars['String']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Apply the updates of a TaskRefinement. */
  applyTaskRefinement: TaskRefinement
  /** Creates a new agent. */
  createAgent: Agent
  /** Creates a new forwarding email address. */
  createForwardingEmailAddress: ForwardingEmailAddress
  /** Creates a new message. */
  createMessage: Message
  /** Creates a new note. */
  createNote: Note
  /** Creates a new space. */
  createSpace: Space
  /** Creates a new task. */
  createTask: Task
  /** Delete a file. */
  deleteFile?: Maybe<Scalars['Void']['output']>
  /** Deletes a note. */
  deleteNote?: Maybe<Scalars['Void']['output']>
  /** Deletes notifications by id. */
  deleteNotifications?: Maybe<Scalars['Void']['output']>
  /** Deletes a space and all tasks within it. */
  deleteSpace?: Maybe<Scalars['Void']['output']>
  /** Deletes a task by id. */
  deleteTask?: Maybe<Scalars['Void']['output']>
  /** Deletes a thread event and its payload. */
  deleteThreadEvent?: Maybe<Scalars['Void']['output']>
  /** Set notification isUnread status. */
  markNotificationsAsRead: Array<Notification>
  /** Moves subtasks to a new position in a subtask list. Returns the updated parent task of the subtasks. */
  orderSubtasks: Task
  /** Moves tasks to a new position in a task list. */
  orderTasks?: Maybe<Scalars['Void']['output']>
  /** Create a refinement of a Task. */
  refineTask: TaskRefinement
  runFunction: RunFunctionResponse
  /** Set task status by id. */
  setTaskStatus: Task
  /** Updates an agent. */
  updateAgent: Agent
  /** Updates a note. */
  updateNote: Note
  /** Updates a space. */
  updateSpace: Space
  /** Updates a task. */
  updateTask: Task
  /** Upload a file. */
  uploadFile: FileMetadataImageFileMetadata
}

export type MutationApplyTaskRefinementArgs = {
  input: ApplyTaskRefinementInput
}

export type MutationCreateAgentArgs = {
  input: CreateAgentInput
}

export type MutationCreateMessageArgs = {
  input: CreateMessageInput
}

export type MutationCreateNoteArgs = {
  input: CreateNoteInput
}

export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput
}

export type MutationCreateTaskArgs = {
  input: CreateTaskInput
}

export type MutationDeleteFileArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNoteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNotificationsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationDeleteSpaceArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteThreadEventArgs = {
  id: Scalars['ID']['input']
}

export type MutationMarkNotificationsAsReadArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationOrderSubtasksArgs = {
  input: OrderSubtasksInput
}

export type MutationOrderTasksArgs = {
  input: OrderTasksInput
}

export type MutationRefineTaskArgs = {
  input: RefineTaskInput
}

export type MutationRunFunctionArgs = {
  input: RunFunctionInput
}

export type MutationSetTaskStatusArgs = {
  input: SetTaskStatusInput
}

export type MutationUpdateAgentArgs = {
  input: UpdateAgentInput
}

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput
}

export type MutationUpdateSpaceArgs = {
  input: UpdateSpaceInput
}

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput
}

export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input']
}

/** Represents a note. */
export type Note = {
  __typename?: 'Note'
  /** The body of the note. */
  body?: Maybe<Scalars['String']['output']>
  /** The date and time the note was created. */
  createdAt: Scalars['DateTime']['output']
  /** The user who created the note. */
  creator: User
  /** The files attached to the note. */
  files: Array<FileMetadataImageFileMetadata>
  /** The id of the note. Automatically generated. Ordered from oldest to newest when sorted lexicographically. */
  id: Scalars['ID']['output']
  /** The title of the note. Should be non-empty. */
  title: Scalars['String']['output']
}

/** A notification send to a user. */
export type Notification = {
  __typename?: 'Notification'
  /** The date and time the message was created. */
  createdAt: Scalars['DateTime']['output']
  /** The automatically generated id of a notification. Ordered from oldest to newest. */
  id: Scalars['ID']['output']
  /** A flag indicating whether the notifcation is unread by the viewer. */
  isUnread: Scalars['Boolean']['output']
  /** The original message that triggered the notification. */
  sourceMessage?: Maybe<Message>
  /** The task associated with the notification. */
  task?: Maybe<Task>
}

export type OrderSubtasksInput = {
  /** The id of a distinct subtask to insert after. */
  insertAfterSubtaskWithId?: InputMaybe<Scalars['ID']['input']>
  /** The ids of the subtasks to move. */
  orderedSubtaskIds: Array<Scalars['ID']['input']>
}

export type OrderTasksInput = {
  /** The id of a distinct task to insert after. */
  insertAfterTaskWithId?: InputMaybe<Scalars['ID']['input']>
  /** Ordered task ids. */
  taskIds: Array<Scalars['ID']['input']>
}

export type PreviousTaskRefinementFeedbackInput = {
  /** The id of a previous TaskRefinement. */
  taskRefinementId: Scalars['ID']['input']
  /** The user feedback on the TaskRefinement. */
  userFeedback: Array<Scalars['String']['input']>
}

export type Query = {
  __typename?: 'Query'
  /** Retrieve a list of agents. */
  agents: Array<Agent>
  /** Retrieve activity flow data for a task. */
  getTaskActivityFlowData: Array<ActivityFlowDataNode>
  /** Retrieve a list of notes. */
  notes: Array<Note>
  /** Retrieve a list of similar tasks. */
  similarTasks: Array<SimilarTaskResult>
  /** Retrieve all spaces. */
  spaces: Array<Space>
  /** Retrieve a list of tasks. */
  tasks: Array<Task>
  /** Retrieve a list of users. */
  users: Array<User>
  /** Retrieve viewer data. */
  viewer: Viewer
}

export type QueryGetTaskActivityFlowDataArgs = {
  taskId: Scalars['ID']['input']
}

export type QuerySimilarTasksArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  minSimilarityScore?: InputMaybe<Scalars['Float']['input']>
  taskId: Scalars['ID']['input']
}

export type QueryTasksArgs = {
  first?: InputMaybe<Scalars['Int']['input']>
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  spaceId?: InputMaybe<Scalars['ID']['input']>
}

export type RefineTaskInput = {
  /** An optional set of ids of Tasks to use as precedent for refining the Task. When omitted, the set of Tasks to be used as precedent will be determined automatically. */
  precedentTaskIds?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Optional feedback for a previous TaskRefinement that should be considered when refining the task. */
  previousTaskRefinementFeedback?: InputMaybe<PreviousTaskRefinementFeedbackInput>
  /** The id of the task to refine. */
  taskId: Scalars['ID']['input']
}

export type RunFunctionInput = {
  query: Scalars['String']['input']
}

export type RunFunctionResponse = {
  __typename?: 'RunFunctionResponse'
  status: Scalars['String']['output']
}

export type SetTaskStatusInput = {
  /** The id of the task to update. */
  id: Scalars['ID']['input']
  /** The new task status to be set. */
  status: TaskStatus
}

/**
 *
 * Tasks that have been deemed similar along with their respective similarity score.
 *
 */
export type SimilarTaskResult = {
  __typename?: 'SimilarTaskResult'
  /** The similarity score of the task to the original task. */
  score: Scalars['Float']['output']
  /** The task associated with the similarity score. */
  task: Task
}

/** A space is a way of grouping tasks by domain. */
export type Space = {
  __typename?: 'Space'
  /** The id of the space. Automatically generated. Ordered from oldest to newest when sorted lexicographically. */
  id: Scalars['ID']['output']
  /** The name of the space. Must be non-empty. */
  name: Scalars['String']['output']
}

/**
 *
 * A task represents a unit of work that is to be completed.
 * Collections of tasks and their subtasks are used to track, plan, and structure work.
 *
 */
export type Task = {
  __typename?: 'Task'
  /** The user assigned to the task. */
  assignee?: Maybe<User>
  /** The date and time the task was completed. */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  /** The date and time the task was created. */
  createdAt: Scalars['DateTime']['output']
  /** Optional description of a task. */
  description?: Maybe<Scalars['String']['output']>
  /** The due date until the task should be completed. */
  dueAt?: Maybe<Scalars['DateTime']['output']>
  /** The id of the task. Automatically generated. Ordered from oldest to newest when sorted lexicographically. */
  id: Scalars['ID']['output']
  /** The parent task of the task. */
  parentTask?: Maybe<Task>
  /** The space the task belongs to. */
  space: Space
  /** The status of the task */
  status: TaskStatus
  /** The subtasks of the task. */
  subtasks: Array<Task>
  /** The thread associated with the task. */
  thread: Thread
  /** The title of the task. */
  title: Scalars['String']['output']
}

/**
 *
 * A new Task or a change to be made to an existing Task.
 *
 */
export type TaskChange = {
  __typename?: 'TaskChange'
  /** An updated parent Task id. Pass `null` to orphan the Task. */
  parentTaskId?: Maybe<Scalars['ID']['output']>
  /** An updated title to be applied to the Task. */
  title?: Maybe<Scalars['String']['output']>
}

/**
 *
 * A proposed set of changes aimed to improve the execution of a Task.
 *
 */
export type TaskRefinement = {
  __typename?: 'TaskRefinement'
  /** The id of the TaskRefinement. */
  id: Scalars['ID']['output']
  /** The set of Tasks that are used as precedent for the refinement. */
  precedentTasks: Array<Task>
  /** The Task that should be refined. */
  task: Task
  /** The set of Change Requests that make up the refinement. */
  updates: Array<ChangeRequest>
}

export type TaskStatus = 'CANCELLED' | 'COMPLETED' | 'OPEN' | 'STARTED'

/** A task update event. */
export type TaskUpdate = {
  __typename?: 'TaskUpdate'
  /** The actor who triggered the task update. */
  actor: User
  /** The task that was affected by the update. */
  affectedTask: Task
  /** The date and time the task update occurred. */
  createdAt: Scalars['DateTime']['output']
  /** The id of the task update. */
  id: Scalars['ID']['output']
  /** The type of task update. */
  type: TaskUpdateType
}

export type TaskUpdateType =
  | 'CREATED'
  | 'STATUS_CANCELLED'
  | 'STATUS_COMPLETED'
  | 'STATUS_OPENED'
  | 'STATUS_STARTED'

/** A thread represents a collection of messages. */
export type Thread = {
  __typename?: 'Thread'
  /** The events in the thread. */
  events: Array<ThreadEvent>
  /** The id of the thread. Automatically generated. */
  id: Scalars['ID']['output']
  /** Whether the viewer has updates in the task that they have not seen yet. */
  viewerHasUpdates: Scalars['Boolean']['output']
  /** The number of new mentions in the task that the viewer has not seen yet. */
  viewerNewMentionCount: Scalars['Int']['output']
}

/** An event that is part of the thread. */
export type ThreadEvent = {
  __typename?: 'ThreadEvent'
  /** The date and time the event was created. */
  createdAt: Scalars['DateTime']['output']
  /** The id of the thread event. */
  id: Scalars['ID']['output']
  /** The payload of the event. */
  payload: Array<ThreadEventPayload>
}

export type ThreadEventPayload =
  | AgentInvocation
  | FileMetadata
  | ImageFileMetadata
  | Message
  | TaskUpdate

/** A tool that can be called by an agent. */
export type Tool = {
  __typename?: 'Tool'
  /** The id of the tool. */
  id: Scalars['ID']['output']
  /** The name of the tool. */
  name: Scalars['String']['output']
}

/** A tool call step in the agent invocation. */
export type ToolCallStep = {
  __typename?: 'ToolCallStep'
  /** The date and time the tool call step completed. The step is still in progress if this field is null. */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  /** The date and time the tool call step was created. */
  createdAt: Scalars['DateTime']['output']
  /** The id of the tool call step. */
  id: Scalars['ID']['output']
  /** A string representation of the input of the tool. */
  input: Scalars['String']['output']
  /** A string representation of the output of the tool. */
  output?: Maybe<Scalars['String']['output']>
  /** The tool that was called in this step. */
  tool: Tool
}

export type UpdateAgentInput = {
  /** Optional updated avatar URL of the agent. */
  avatarUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updated instructions for the agent. */
  behavioralInstructions?: InputMaybe<Scalars['String']['input']>
  /** The ID of the agent to be updated. */
  id: Scalars['ID']['input']
  /** Optional updated name of the agent */
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateNoteInput = {
  /** An optional updated body of a note. */
  body?: InputMaybe<Scalars['String']['input']>
  /** The id of the note to update. */
  id: Scalars['ID']['input']
  /** An optional updated title of the note. Can not be empty string. */
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSpaceInput = {
  /** The ID of the space to update. */
  id: Scalars['ID']['input']
  /** The new name for the space. Cannot be empty. */
  name: Scalars['String']['input']
}

export type UpdateTaskInput = {
  /** The id of an optional user to assign the task to. */
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  /** An optional updated description of a task. */
  description?: InputMaybe<Scalars['String']['input']>
  /** An optional due date of a task. Should use ISO string. */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  /** The id of the task to update. */
  id: Scalars['ID']['input']
  /** The id of the parent task. */
  parentTaskId?: InputMaybe<Scalars['ID']['input']>
  /** The id of a space to which the task should be moved. If set to `null`, the task is moved to the default space. */
  spaceId?: InputMaybe<Scalars['ID']['input']>
  /** An optional updated title of the task. Can not be empty string. */
  title?: InputMaybe<Scalars['String']['input']>
}

/**
 *
 * A user represents a person who can be assigned to tasks.
 *
 */
export type User = {
  __typename?: 'User'
  /** The url of the user's avatar. */
  avatarUrl?: Maybe<Scalars['String']['output']>
  /** The id of the user. */
  id: Scalars['ID']['output']
  /** The name of the user. */
  name: Scalars['String']['output']
}

/**
 *
 * A viewer represents the currently signed in user based on the access token.
 *
 */
export type Viewer = {
  __typename?: 'Viewer'
  /** The url of the user's avatar. */
  avatarUrl?: Maybe<Scalars['String']['output']>
  /** The id of the viewer. */
  id: Scalars['ID']['output']
  /** The name of the user. */
  name: Scalars['String']['output']
  /** The notifications sent to the viewer. */
  notifications: Array<Notification>
  /** The tasks a viewer is assigned to. */
  tasks: Array<Task>
}

export type ApplyTaskRefinementMutationVariables = Exact<{
  refinementId: Scalars['ID']['input']
}>

export type ApplyTaskRefinementMutation = {
  __typename?: 'Mutation'
  applyTaskRefinement: { __typename?: 'TaskRefinement'; id: string }
}

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput
}>

export type CreateMessageMutation = {
  __typename?: 'Mutation'
  createMessage: {
    __typename?: 'Message'
    id: string
    createdAt: string
    author: { __typename?: 'User'; id: string; name: string }
    body: { __typename?: 'MessageBody'; text: string }
  }
}

export type CreateTaskMutationVariables = Exact<{
  taskInput: CreateTaskInput
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'Task'
    id: string
    title: string
    createdAt: string
    status: TaskStatus
    assignee?: { __typename?: 'User'; id: string } | null
    thread: { __typename?: 'Thread'; id: string }
  }
}

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type DeleteTaskMutation = {
  __typename?: 'Mutation'
  deleteTask?: any | null
}

export type DeleteThreadEventMutationVariables = Exact<{
  threadEventId: Scalars['ID']['input']
}>

export type DeleteThreadEventMutation = {
  __typename?: 'Mutation'
  deleteThreadEvent?: any | null
}

export type DeleteNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type DeleteNotificationsMutation = {
  __typename?: 'Mutation'
  deleteNotifications?: any | null
}

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteNoteMutation = {
  __typename?: 'Mutation'
  deleteNote?: any | null
}

export type FetchAllUsersQueryVariables = Exact<{ [key: string]: never }>

export type FetchAllUsersQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'User'
    id: string
    name: string
    avatarUrl?: string | null
  }>
}

export type FetchAllTasksQueryVariables = Exact<{
  spaceId?: InputMaybe<Scalars['ID']['input']>
}>

export type FetchAllTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    id: string
    title: string
    createdAt: string
    completedAt?: string | null
    status: TaskStatus
    dueAt?: string | null
    assignee?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl?: string | null
    } | null
  }>
}

export type SimilarTasksQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
  minSimilarityScore?: InputMaybe<Scalars['Float']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type SimilarTasksQuery = {
  __typename?: 'Query'
  similarTasks: Array<{
    __typename?: 'SimilarTaskResult'
    task: {
      __typename?: 'Task'
      id: string
      title: string
      description?: string | null
      completedAt?: string | null
      status: TaskStatus
      assignee?: {
        __typename?: 'User'
        id: string
        name: string
        avatarUrl?: string | null
      } | null
    }
  }>
}

export type FetchSpacesQueryVariables = Exact<{ [key: string]: never }>

export type FetchSpacesQuery = {
  __typename?: 'Query'
  spaces: Array<{ __typename?: 'Space'; id: string; name: string }>
}

export type GetThreadEventsQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type GetThreadEventsQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    thread: {
      __typename?: 'Thread'
      id: string
      events: Array<{
        __typename?: 'ThreadEvent'
        id: string
        createdAt: string
        payload: Array<
          | {
              __typename: 'AgentInvocation'
              id: string
              createdAt: string
              completedAt?: string | null
              agent: {
                __typename?: 'Agent'
                id: string
                name: string
                avatarUrl?: string | null
              }
              steps: Array<{
                __typename?: 'ToolCallStep'
                id: string
                input: string
                output?: string | null
                tool: { __typename?: 'Tool'; name: string }
              }>
            }
          | {
              __typename: 'FileMetadata'
              id: string
              name: string
              fileUrl: string
              size: number
              mimeType: string
            }
          | {
              __typename: 'ImageFileMetadata'
              id: string
              name: string
              fileUrl: string
              size: number
              width: number
              height: number
            }
          | {
              __typename: 'Message'
              id: string
              author: {
                __typename?: 'User'
                avatarUrl?: string | null
                id: string
                name: string
              }
              body: { __typename?: 'MessageBody'; text: string }
            }
          | {
              __typename: 'TaskUpdate'
              id: string
              type: TaskUpdateType
              actor: {
                __typename?: 'User'
                avatarUrl?: string | null
                id: string
                name: string
              }
              affectedTask: {
                __typename?: 'Task'
                id: string
                description?: string | null
                title: string
                status: TaskStatus
              }
            }
        >
      }>
    }
  }>
}

export type FetchTasksByIdQueryVariables = Exact<{
  taskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type FetchTasksByIdQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    id: string
    title: string
    createdAt: string
    completedAt?: string | null
    status: TaskStatus
    dueAt?: string | null
    description?: string | null
    space: { __typename?: 'Space'; id: string; name: string }
    assignee?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl?: string | null
    } | null
    thread: { __typename?: 'Thread'; id: string }
    subtasks: Array<{
      __typename?: 'Task'
      id: string
      title: string
      description?: string | null
      createdAt: string
      completedAt?: string | null
      status: TaskStatus
      assignee?: {
        __typename?: 'User'
        id: string
        name: string
        avatarUrl?: string | null
      } | null
    }>
    parentTask?: {
      __typename?: 'Task'
      id: string
      title: string
      completedAt?: string | null
      status: TaskStatus
      parentTask?: { __typename?: 'Task'; id: string } | null
    } | null
  }>
}

export type MarkNotificationsAsReadMutationVariables = Exact<{
  notificationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type MarkNotificationsAsReadMutation = {
  __typename?: 'Mutation'
  markNotificationsAsRead: Array<{
    __typename?: 'Notification'
    id: string
    isUnread: boolean
  }>
}

export type OrderSubtasksMutationVariables = Exact<{
  orderedSubtaskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  insertAfterSubtaskWithId?: InputMaybe<Scalars['ID']['input']>
}>

export type OrderSubtasksMutation = {
  __typename?: 'Mutation'
  orderSubtasks: {
    __typename?: 'Task'
    subtasks: Array<{ __typename?: 'Task'; id: string }>
  }
}

export type OrderTasksMutationVariables = Exact<{
  taskIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  insertAfterTaskWithId?: InputMaybe<Scalars['ID']['input']>
}>

export type OrderTasksMutation = {
  __typename?: 'Mutation'
  orderTasks?: any | null
}

export type RefineTaskMutationVariables = Exact<{
  taskId: Scalars['ID']['input']
  precedentTaskIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
  previousTaskRefinementFeedback?: InputMaybe<PreviousTaskRefinementFeedbackInput>
}>

export type RefineTaskMutation = {
  __typename?: 'Mutation'
  refineTask: {
    __typename?: 'TaskRefinement'
    id: string
    updates: Array<{
      __typename?: 'ChangeRequest'
      change: {
        __typename?: 'TaskChange'
        parentTaskId?: string | null
        title?: string | null
      }
    }>
    precedentTasks: Array<{
      __typename?: 'Task'
      id: string
      title: string
      completedAt?: string | null
      createdAt: string
      assignee?: {
        __typename?: 'User'
        name: string
        avatarUrl?: string | null
      } | null
    }>
  }
}

export type SetTaskStatusMutationVariables = Exact<{
  id: Scalars['ID']['input']
  status: TaskStatus
}>

export type SetTaskStatusMutation = {
  __typename?: 'Mutation'
  setTaskStatus: { __typename?: 'Task'; id: string; status: TaskStatus }
}

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
  body?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateNoteMutation = {
  __typename?: 'Mutation'
  updateNote: {
    __typename?: 'Note'
    id: string
    title: string
    body?: string | null
  }
}

export type CreateNoteMutationVariables = Exact<{
  title: Scalars['String']['input']
  body?: InputMaybe<Scalars['String']['input']>
  fileIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type CreateNoteMutation = {
  __typename?: 'Mutation'
  createNote: {
    __typename?: 'Note'
    id: string
    title: string
    body?: string | null
  }
}

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  assigneeId?: InputMaybe<Scalars['ID']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  spaceId?: InputMaybe<Scalars['ID']['input']>
  parentTaskId?: InputMaybe<Scalars['ID']['input']>
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask: {
    __typename?: 'Task'
    id: string
    title: string
    description?: string | null
    dueAt?: string | null
    status: TaskStatus
    assignee?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl?: string | null
    } | null
  }
}

export type FetchViewerQueryVariables = Exact<{ [key: string]: never }>

export type FetchViewerQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    id: string
    name: string
    avatarUrl?: string | null
  }
}

export type FetchViewerWithTasksQueryVariables = Exact<{ [key: string]: never }>

export type FetchViewerWithTasksQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    id: string
    name: string
    avatarUrl?: string | null
    tasks: Array<{
      __typename?: 'Task'
      id: string
      title: string
      description?: string | null
      completedAt?: string | null
      status: TaskStatus
      parentTask?: { __typename?: 'Task'; id: string; title: string } | null
      thread: {
        __typename?: 'Thread'
        viewerNewMentionCount: number
        viewerHasUpdates: boolean
      }
      assignee?: { __typename?: 'User'; id: string } | null
    }>
  }
}

export type FetchViewerWithNotificationsQueryVariables = Exact<{
  [key: string]: never
}>

export type FetchViewerWithNotificationsQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    id: string
    name: string
    avatarUrl?: string | null
    notifications: Array<{
      __typename?: 'Notification'
      id: string
      createdAt: string
      isUnread: boolean
      sourceMessage?: {
        __typename?: 'Message'
        id: string
        createdAt: string
        author: {
          __typename?: 'User'
          id: string
          name: string
          avatarUrl?: string | null
        }
        body: { __typename?: 'MessageBody'; text: string }
      } | null
      task?: { __typename?: 'Task'; id: string } | null
    }>
  }
}

export type FetchActivityFlowDataQueryVariables = Exact<{
  taskId: Scalars['ID']['input']
}>

export type FetchActivityFlowDataQuery = {
  __typename?: 'Query'
  getTaskActivityFlowData: Array<{
    __typename?: 'ActivityFlowDataNode'
    nodeId: number
    parentId?: number | null
    activityCount: number
    summary: string
    activities: Array<{ __typename?: 'Activity'; id: string }>
    adjacentNodes: Array<{
      __typename?: 'AdjacentActivityFlowDataNode'
      nodeId: number
      traceCount: number
    }>
  }>
}

export type FetchNotesQueryVariables = Exact<{ [key: string]: never }>

export type FetchNotesQuery = {
  __typename?: 'Query'
  notes: Array<{
    __typename?: 'Note'
    id: string
    body?: string | null
    title: string
    files: Array<
      | { __typename: 'FileMetadata'; id: string; type: string }
      | { __typename: 'ImageFileMetadata'; id: string; type: string }
    >
  }>
}

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteFileMutation = {
  __typename?: 'Mutation'
  deleteFile?: any | null
}

export type FetchAgentsQueryVariables = Exact<{ [key: string]: never }>

export type FetchAgentsQuery = {
  __typename?: 'Query'
  agents: Array<{
    __typename?: 'Agent'
    id: string
    name: string
    avatarUrl?: string | null
    behavioralInstructions?: string | null
  }>
}

export type CreateAgentMutationVariables = Exact<{
  name: Scalars['String']['input']
  behavioralInstructions?: InputMaybe<Scalars['String']['input']>
  avatarUrl?: InputMaybe<Scalars['String']['input']>
}>

export type CreateAgentMutation = {
  __typename?: 'Mutation'
  createAgent: {
    __typename?: 'Agent'
    id: string
    name: string
    avatarUrl?: string | null
    behavioralInstructions?: string | null
  }
}

export type UpdateAgentMutationVariables = Exact<{
  id: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  behavioralInstructions?: InputMaybe<Scalars['String']['input']>
  avatarUrl?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateAgentMutation = {
  __typename?: 'Mutation'
  updateAgent: {
    __typename?: 'Agent'
    id: string
    name: string
    avatarUrl?: string | null
    behavioralInstructions?: string | null
  }
}

export type CreateForwardingEmailAddressMutationVariables = Exact<{
  [key: string]: never
}>

export type CreateForwardingEmailAddressMutation = {
  __typename?: 'Mutation'
  createForwardingEmailAddress: {
    __typename?: 'ForwardingEmailAddress'
    localPart: string
  }
}

export type TestTasksQueryVariables = Exact<{ [key: string]: never }>

export type TestTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{ __typename?: 'Task'; id: string }>
}

export const ApplyTaskRefinementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'applyTaskRefinement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refinementId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyTaskRefinement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'refinementId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplyTaskRefinementMutation,
  ApplyTaskRefinementMutationVariables
>
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateMessageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'author' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'body' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageMutation,
  CreateMessageMutationVariables
>
export const CreateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateTaskInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTaskMutation, CreateTaskMutationVariables>
export const DeleteTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteTaskMutation, DeleteTaskMutationVariables>
export const DeleteThreadEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteThreadEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'threadEventId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteThreadEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'threadEventId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteThreadEventMutation,
  DeleteThreadEventMutationVariables
>
export const DeleteNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ids' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteNotificationsMutation,
  DeleteNotificationsMutationVariables
>
export const DeleteNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteNoteMutation, DeleteNoteMutationVariables>
export const FetchAllUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchAllUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAllUsersQuery, FetchAllUsersQueryVariables>
export const FetchAllTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchAllTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spaceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'spaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'spaceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAllTasksQuery, FetchAllTasksQueryVariables>
export const SimilarTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SimilarTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'minSimilarityScore' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'similarTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'minSimilarityScore' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'minSimilarityScore' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'task' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SimilarTasksQuery, SimilarTasksQueryVariables>
export const FetchSpacesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchSpaces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spaces' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchSpacesQuery, FetchSpacesQueryVariables>
export const GetThreadEventsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetThreadEvents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'taskId' },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'events' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'payload' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'Message' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'author',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'avatarUrl',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'body' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'text',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'TaskUpdate',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'actor',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'avatarUrl',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'affectedTask',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'description',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'status',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'FileMetadata',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fileUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'size' },
                                        },
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'mimeType',
                                          },
                                          name: { kind: 'Name', value: 'type' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'ImageFileMetadata',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fileUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'size' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'width',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'height',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'AgentInvocation',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'createdAt',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'completedAt',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'agent',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'name',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'avatarUrl',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'steps',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'input',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'output',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'tool',
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'name',
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetThreadEventsQuery,
  GetThreadEventsQueryVariables
>
export const FetchTasksByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchTasksById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'space' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thread' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentTask' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentTask' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchTasksByIdQuery, FetchTasksByIdQueryVariables>
export const MarkNotificationsAsReadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkNotificationsAsRead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'notificationIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markNotificationsAsRead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'notificationIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isUnread' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkNotificationsAsReadMutation,
  MarkNotificationsAsReadMutationVariables
>
export const OrderSubtasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderSubtasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderedSubtaskIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insertAfterSubtaskWithId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderSubtasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'orderedSubtaskIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orderedSubtaskIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'insertAfterSubtaskWithId' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'insertAfterSubtaskWithId',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrderSubtasksMutation,
  OrderSubtasksMutationVariables
>
export const OrderTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'OrderTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insertAfterTaskWithId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orderTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'insertAfterTaskWithId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'insertAfterTaskWithId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'taskIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskIds' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrderTasksMutation, OrderTasksMutationVariables>
export const RefineTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'refineTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'precedentTaskIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'previousTaskRefinementFeedback' },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'PreviousTaskRefinementFeedbackInput',
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refineTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'taskId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'taskId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'precedentTaskIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'precedentTaskIds' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: {
                        kind: 'Name',
                        value: 'previousTaskRefinementFeedback',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'previousTaskRefinementFeedback',
                        },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updates' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'change' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentTaskId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'precedentTasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RefineTaskMutation, RefineTaskMutationVariables>
export const SetTaskStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetTaskStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'status' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TaskStatus' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setTaskStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SetTaskStatusMutation,
  SetTaskStatusMutationVariables
>
export const UpdateNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'body' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'body' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateNoteMutation, UpdateNoteMutationVariables>
export const CreateNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'body' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fileIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'body' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'body' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'fileIds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fileIds' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateNoteMutation, CreateNoteMutationVariables>
export const UpdateTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'title' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assigneeId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'spaceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parentTaskId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'description' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'assigneeId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'assigneeId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'dueAt' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dueAt' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'spaceId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'spaceId' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parentTaskId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentTaskId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateTaskMutation, UpdateTaskMutationVariables>
export const FetchViewerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchViewer' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewerQuery, FetchViewerQueryVariables>
export const FetchViewerWithTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchViewerWithTasks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tasks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentTask' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thread' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'viewerNewMentionCount',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'viewerHasUpdates' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignee' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchViewerWithTasksQuery,
  FetchViewerWithTasksQueryVariables
>
export const FetchViewerWithNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchViewerWithNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'notifications' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUnread' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sourceMessage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'author' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatarUrl' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'body' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'task' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchViewerWithNotificationsQuery,
  FetchViewerWithNotificationsQueryVariables
>
export const FetchActivityFlowDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchActivityFlowData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'taskId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTaskActivityFlowData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'taskId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'taskId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nodeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adjacentNodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodeId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'traceCount' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchActivityFlowDataQuery,
  FetchActivityFlowDataQueryVariables
>
export const FetchNotesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchNotes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'notes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'FileMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ImageFileMetadata' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchNotesQuery, FetchNotesQueryVariables>
export const DeleteFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteFileMutation, DeleteFileMutationVariables>
export const FetchAgentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchAgents' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'agents' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'behavioralInstructions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAgentsQuery, FetchAgentsQueryVariables>
export const CreateAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'behavioralInstructions' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatarUrl' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'avatarUrl' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'behavioralInstructions' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'behavioralInstructions' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'behavioralInstructions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateAgentMutation, CreateAgentMutationVariables>
export const UpdateAgentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAgent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'behavioralInstructions' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'avatarUrl' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAgent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'avatarUrl' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'behavioralInstructions' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'behavioralInstructions' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'behavioralInstructions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAgentMutation, UpdateAgentMutationVariables>
export const CreateForwardingEmailAddressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateForwardingEmailAddress' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createForwardingEmailAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'localPart' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateForwardingEmailAddressMutation,
  CreateForwardingEmailAddressMutationVariables
>
export const TestTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TestTasks' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tasks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestTasksQuery, TestTasksQueryVariables>
